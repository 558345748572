import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const sections = [
  {
    image: 'pc.svg',
    title: 'Enhanced Product Development',
    description: 'Your insights are invaluable. By participating, you’ll help us optimize user experience, identify key features for development, and address potential issues, ensuring a successful market launch. Your feedback will directly shape the platform, making it more intuitive, powerful, and aligned with industry needs.'
  },
  {
    image: 'square.svg',
    title: 'Exclusive Insights and Early Access',
    description: 'Gain access to exclusive features and tools before they’re available to the broader market. As a beta tester, you’ll be the first to explore new functionalities, giving your firm a competitive edge in the rapidly evolving investment landscape.'
  },
  {
    image: 'building.svg',
    title: 'Tailored Solutions for Your Firm',
    description: 'Participating in the beta program allows you to provide feedback that can directly influence the development of features most relevant to your firm’s specific needs. This ensures that when the platform launches, it’s tailored to help your firm achieve its unique investment objectives.'
  },
  {
    image: 'users.svg',
    title: 'Be Part of a Forward-Thinking Community',
    description: 'Join a select group of industry leaders who are shaping the future of investment technology. Engage with peers who share your commitment to innovation and be part of a community that values collaboration and forward-thinking solutions.'
  }
];

export default function SectionSlider() {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Disable default arrows
    
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <section className='p-10'>
      <div className='container mx-auto px-4'>
        <h2 className='text-2xl font-semibold text-center mb-6'>Why Join?</h2>
        <div className='relative'>
          <Slider {...settings} ref={sliderRef}>
            {sections.map((section, index) => (
             <div key={index} className='relative p-4 flex justify-center items-center'>
             <div className='container pt-10 relative max-w-[1240px] min-h-[250px] bg-blue'>
               <div className='container text-left max-w-[1000px] '>
                <img className='p-4' alt="" src={section.image} />
               
                 <h4 className='text-l font-bold'>{section.title}</h4>
                 <p className='text-m mt-2'>{section.description}</p>
               </div>
             </div>
           </div>
            ))}
          </Slider>
          <button
            onClick={handlePrev} aria-label='previous'
            className='absolute top-1/2  transform -translate-y-1/2  p-2 rounded-full focus:outline-none' style={{ left: '-7vw' }}>
            <img  alt="" src="arrow-l.svg"/>
          </button>
          <button
            onClick={handleNext} aria-label='next'
            className='absolute top-1/2 right-0 transform -translate-y-1/2  p-2 rounded-full focus:outline-none' style={{ right: '-7vw' }}>
            <img  alt="" src="arrow-r.svg"/>
          </button>
        </div>
      </div>
    </section>
  );
}
 