import './App.css';
import './index.css';
import Header from './Components/Header';
import Section1 from './Components/Section1';
import VideoKC from './Components/VideoKC';
import SectionSlider from './Components/SectionSlider';
import Participants from './Components/Participants';
import Section2 from './Components/Section2';
import Apply from './Components/Apply';
import Footer from './Components/Footer';

function App() {
  return (
    <>
      <Header/>
      <Section1/>
      <VideoKC/>
      <SectionSlider/>
      <Participants/>
      <Section2/>
      <Apply/> 
      <Footer/>
    </>
  );
}

export default App;
