import React,{useState,useEffect} from 'react'

export default function Header() {
   

    return (
        <section className="header-bg min-h-[350px]  pb-5 w-full">
            {/* Logo at the top left */}
            <div className="w-full flex justify-start items-start pt-[5%] pl-[5%]">
                <img src="/logo.png" alt="Logo" className="footer-logo" />
            </div>

            <div className={`container flex flex-col md:flex-row items-center w-full lg:pl-[10%] pb-[5%]`}>
                {/* Text column */}
                <div className="text-white w-full w-2/2 order-2 lg:order-1 lg:max-w-[500px] text-center lg:text-left md:text-right mt-[3%]">
                    <h2 className="text-[24px] md:text-[28px] lg:text-[38px] font-noah"><b>Join Konzortia Hub's Founder's Circle Program:</b> Shape the Future of Investment Technology </h2>
                    <p className="mt-3 text-[16px] color-grey">We're inviting select investment firms to help us develop and refine our cutting-edge Deal Sourcing platform</p>
                </div>
                {/* Image first on mobile */}
                <div className="w-full w-2/2 order-1 lg:order-2 md:mt-0">
                    <video controls width="100%" height="auto" preload='true'>
                        <source src="/founderscircle.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>  
                 </div>       
            </div>

            </section>

    );
}