import React, {useState , useEffect } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export const FormModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);
  const [fullName,setFullName]= useState('')
  const [email, setEmail]= useState('')
  const [phone, setPhone]= useState('')
  

  const calledBTN = ()=>{
    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        "email":email,
        "full_name":fullName,
        "phone":phone
       })
    };
  
      try{ 
      fetch('/save.php', requestOptions)
      .then((response)=>{
        if(response.status===200){
          toast.success("Request sent!");
          onClose();
        }else{
          toast.error("An error occurred, Please check details and try again!");
        }
        }) 
      }catch(error){
        toast.error("An error occurred, Please check details and try again!");
        
      }

  }
  return (
    <>
      {isOpen ? (
        <>
          <div className="justify-center items-center min-w-[300px] min-h-[400px] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-[95%] my-6 mx-auto max-w-3xl w-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-2 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={onClose}
                  >
                    <span className=" text-black text-2xl font-label-black block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <article className={`grid w-80 text-white gap-6 m-4`}>
                  {/* Name Field */}
                  <div className="col-span-2">
                    <label htmlFor="fullName" className="block mb-1 label">
                      Name
                    </label>
                    <input
                      id="fullName"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="bg-input w-full h-10 border-grey text-black"
                      type="text"
                      placeholder=""
                    />
                  </div>

                  {/* Email Field */}
                  <div className="col-span-2">
                    <label htmlFor="email" className="block mb-1 label">
                      Email
                    </label>
                    <input
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="bg-input w-full h-10 border-grey text-black"
                      type="email"
                      placeholder=""
                    />
                  </div>

                  {/* Phone Field */}
                  <div className="col-span-2 ">
                    <label htmlFor="phone" className="block mb-1 label">
                      Phone
                    </label>
                    <input
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="bg-input w-full h-10 border-grey text-black"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </article>


                <div className="flex justify-center mt-4 mb-4">
                  <button onClick={calledBTN} className="Konzortia-BTN text-white p-2 pr-8 pl-8 whitespace-nowrap">
                    APPLY NOW
                  </button>
                   
 
          </div>
                {/*footer*/}
                
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
