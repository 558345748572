import React from 'react'


export default function VideoKC() {

  return (
    <section className={` flex flex-row justify-center items-center h-auto`}>
      <div className={` flex flex-col md:flex-row items-center w-full`}>

      <div className="container flex items-center justify-center ">
        <div className="w-full flex flex-col items-center lg:max-w-[850px]">
        <div>
          <video controls width="100%" height="auto">
              <source src="/testimonial.mp4" type="video/mp4" />
                Your browser does not support the video tag.
          </video>   
        </div>
          <p className=" text-[14px] text-center">
            Here's an example of the type of collaboration to expect. This video features two of our members sharing their unique challenges when it comes to investing and raising capital.
          </p>
        </div>
      </div>

     
      </div>
    </section>
  )
}

